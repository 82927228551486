/**
 * Used for Stylising the Widget Remover on Hover button functionality for each report widget.
 * TODO Ideally this probably should be its own Directive/Component.
 */

.widget-remover {
  display: none !important;
  opacity: 0;
  position: absolute !important;
  top: 0px;
  right: 0px;
  z-index: 1;
}

bx-table-widget,
bx-graph-widget,
bx-image-widget,
bx-result-table-widget,
bx-sequence-logo-widget {
  position: relative;
  padding: 14px;
  margin-left: -14px;
  margin-right: -14px;

  &:hover .widget-remover {
    display: block !important;
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    box-shadow: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: box-shadow 200ms ease-in-out;
    // Overlay shouldn't block the Widget interaction via the mouse.
    pointer-events: none;
  }

  &:hover::after {
    content: '';
    box-shadow: 1px 1px 6px grey;
  }
}
