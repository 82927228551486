mat-dialog-container.mat-dialog-container {
  position: relative;

  mat-progress-bar.dialog-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
  }

  mat-dialog-actions.end {
    justify-content: flex-end;
  }
}
