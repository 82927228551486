$border-color: #dee2e6;
$white-opacity-20: rgba(255, 255, 255, 0.2);
$white-opacity-30: rgba(255, 255, 255, 0.3);
$white-opacity-40: rgba(255, 255, 255, 0.4);
$white-opacity-50: rgba(255, 255, 255, 0.5);
$white-opacity-75: rgba(255, 255, 255, 0.75);

// Navigation.
$nav-height: 40px;
// This color is not used anywhere else - it would be nice if it used a theme color instead
$nav-color: #4d5e6f;

// Grid things
$toolstrip-height: 30px;

// Folder tree.
$folder-tree-icon-color: #757575;
$folder-tree-text-color: rgb(84, 84, 84);
$folder-font-size: 12px;

// angular-split gutter size
$as-gutter-size: 12px;

// Default AG Grid Balham selected row background color
$selected-row-bg: rgba(12, 100, 200, 0.12);
