@import './report-variables';
@import './widget-remover';

/**
 * This stylesheet is used for generate-report.component.ts and report.component.ts,
 * which is used for displaying a report page for the Generate Report
 * pipeline to then take a printable screenshot and save it as a PDF.
 * @page CSS queries are not supported properly within Angular components thus the styling is moved to an external
 * stylesheet here.
 */

@media print {
  @page {
    size: A4;
    margin: $report-margin-top $report-margin-right $report-margin-bottom $report-margin-left;
  }

  // Overriding bootstrap that is setting the min-width to 992px for Print for some reason...
  body {
    min-width: auto !important;
  }

  bx-table-widget,
  bx-graph-widget,
  bx-sequence-logo-widget,
  bx-image-widget,
  bx-result-table-widget {
    break-inside: avoid;
    break-after: auto;
  }
}
