@import '../global-variables';

// Fixes height not filling 100%
// Based on issue: https://github.com/angular/material2/issues/4591
.mat-tab-group {
  height: calc(100% - #{$as-gutter-size});
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-body {
  display: flex !important;
  flex-direction: column;
}

.mat-tab-body-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
