@use '@ag-grid-community/styles' as ag;
@use '../geneious-palette.scss' as gp;
@import '../../global-variables';

@include ag.grid-styles(
  (
    theme: balham,
    --ag-border-color: #dee2e6,
    --ag-border-radius: 0,
    --ag-borders-secondary: none,
    --ag-font-size: 13px,
    --ag-header-background-color: white,
    --ag-header-height: 24px,
    --ag-odd-row-background-color: #f6f6f6,
    --ag-row-border-style: none,
    --ag-row-hover-color: rgba(12, 100, 200, 0.06),
    // This is opaque so it hides the alternating row colors (as requested by Megan)
    --ag-selected-row-background-color: #e1ecf8,
    // Checkboxes
    --ag-checkbox-checked-color: gp.$orange-dark,
    --ag-icon-font-code-checkbox-checked: '\e834',
    --ag-icon-font-code-checkbox-unchecked: '\e835',
    --ag-icon-font-code-checkbox-indeterminate: '\e909',
    // Sort icons
    --ag-icon-font-code-asc: '\e5d8',
    --ag-icon-font-code-desc: '\e5db',
  )
);

$header-font-size: 12px;

.ag-theme-balham {
  // Icons that use Material font
  .ag-checkbox-input-wrapper,
  .ag-sort-ascending-icon,
  .ag-sort-descending-icon {
    --ag-icon-font-family: 'Material Icons';
  }

  // Adds a border between the top column group headers and the standard headers.
  .ag-header-row:nth-child(2) > .ag-header-cell {
    border-top: 1px solid var(--ag-border-color, #dee2e6);
    // prevent ungrouped columns from being vertically center-aligned
    // !important is required to override the inline styles
    height: var(--ag-header-height) !important;
    top: 0px !important;
  }

  .ag-checkbox-input-wrapper {
    font-size: 20px;
    font-weight: 400;
    background: none;
    box-shadow: none;
  }

  // Hide the disabled scrollbar that appears under pinned columns
  .ag-horizontal-left-spacer {
    display: none;
  }

  .ag-sort-indicator-container {
    // The multi-sort number was too high up because we shrink the header
    align-items: center;
    // Apply padding to the container rather than the icons so they are grouped together
    padding-left: var(--ag-grid-size);

    .ag-sort-indicator-icon {
      padding-left: 0px;
      font-size: $header-font-size;
    }
  }

  .ag-header-cell-text {
    font-size: $header-font-size;
  }

  .ag-cell,
  .ag-header-cell {
    padding-left: 6px;
    padding-right: 6px;
  }

  // We don't show a loading icon.
  .ag-loading-icon {
    display: none;
  }

  .ag-loading-text {
    // Give some padding so loading text isn't pressed up against the LHS of the table.
    margin-left: 2px;
  }

  .ag-cell.ag-cell-inline-editing {
    // Normally padding is set here at 6px however in the case the user is editing
    // we remove content from this cell and instead need to apply it to the html input element
    // which gets inserted.
    padding: 0 !important;
    $padding: 6px;
    // Apply the outline to the cell rather than the input element below.
    outline: -webkit-focus-ring-color auto 5px;
    // It looks weird if border and outline. Still not sure about this one.
    border: none !important;

    input.ag-cell-edit-input {
      background-color: transparent;
      border: none;
      padding-left: $padding;
      padding-right: $padding;
      // Outline should be applied to the cell (see above) so it's not cropped by the cell
      // and so it doesn't affect the readability of the text.
      outline: none;
    }
  }
}

bx-grid .ag-header-cell {
  justify-content: center;
}
