@use 'sass:map';
// Designed for use with pages like LumaConfigComponent, where a component
// takes up the entire page and would look bad with 100% width.
// Defaults to md
$bx-page-container-xs-width: 380px !default;
$bx-page-container-sm-width: 650px !default;
$bx-page-container-md-width: 800px !default;
$bx-page-container-lg-width: 1000px !default;
$bx-page-container-padding: map.get($spacers, 4) !default; // Equivalent to .p-4

.bx-page-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: $bx-page-container-md-width;
  padding: $bx-page-container-padding;

  &.bx-page-container-xs {
    max-width: $bx-page-container-xs-width;
  }

  &.bx-page-container-sm {
    max-width: $bx-page-container-sm-width;
  }

  &.bx-page-container-lg {
    max-width: $bx-page-container-lg-width;
  }
}
