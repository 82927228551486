// Recaptcha badge styles
.grecaptcha-badge {
  visibility: hidden;
}

.show-captcha-badge {
  .grecaptcha-badge {
    visibility: visible;
  }
}
