// This file is only for custom styles that cannot be achieved with
// Bootstrap's SCSS variable-based theme system

// Custom nav-tab styling to make inactive tabs have a visible border and background
.nav-tabs .nav-item {
  margin-right: $spacer * 0.25;

  .nav-link {
    background-color: $light;
    border-color: $nav-tabs-border-color $nav-tabs-border-color;

    &.active {
      background-color: $nav-tabs-link-active-bg;
      border-color: $nav-tabs-link-active-border-color;
    }

    &:hover {
      border-color: $nav-tabs-link-hover-border-color;
    }
  }
}

// Customize btn-light to have a border
.btn.btn-light {
  border-color: $gray-300;

  &:disabled {
    border: $btn-border-width solid hsl(240deg, 4%, 89%) !important;
  }
}

// Large progress bar (used in assay data dialog). Meant to be the same height as a button.
ngb-progressbar.bx-progress-bar-lg {
  height: 30.81px;
  font-size: $font-size-base;
}

// Add badge variant that uses a normal font size rather than small
.badge.badge-lg {
  font-size: $font-size-base;
}

// A popover without padding or a border
.bx-popover-flush {
  border: 0;
  .popover-body {
    padding: 0;
  }
}

// Resets font size to base for complex popovers containing form elements
// Popovers use a small font size by default
.bx-popover-fs-base {
  --bs-popover-font-size: var(--bs-body-font-size);
  font-size: var(--bs-popover-font-size);
}

// Used for toggling sidebars
.bx-gutter-button {
  width: $spacer;
}

// Sets the default selected row color used by AG Grid Balham. It would be
// preferable to use a background color from the Bootstrap theme. Ideally,
// the grid would be customized to use Bootstrap theme colors as well.
.bg-selected-folder-tree {
  background-color: $selected-row-bg;
}

// Hide the accordion border on collapsed panels.
// This is needed because we still use the Bootstrap 4 card-style accordions with
// a border on the panel header. When they are collapsed, the borders double up.
.bx-card-accordion > .accordion-item:not(:has(> .show, > .collapsing)) {
  --bs-accordion-border-width: 0;
}

// Use native inputs for checkboxes and radios until we sort out the theme colors
.form-check-input {
  appearance: auto !important;
}
