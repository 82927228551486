// Override background-color of form-field underline.
// This colour was changed in BETA.10 release of Material
// https://github.com/angular/material2/pull/5155
.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.mat-form-field.full-width {
  width: 100%;
}
