@use '../geneious-palette' as gp;

.report-grid-theme {
  --ag-header-background-color: #{gp.$orange-dark};
  --ag-header-cell-hover-background-color: #{gp.$orange};
  --ag-header-foreground-color: #{gp.$white};
  --ag-even-row-background-color: rgb(243, 243, 243);

  overflow: hidden;

  .ag-header-cell,
  .ag-header-group-cell {
    cursor: grab;
    display: inline-flex;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 12px;
    font-weight: bold;

    .ag-cell-label-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .ag-cell-menu-button {
        display: none;
      }
    }
  }

  .ag-cell {
    padding-left: 11px;
    padding-right: 11px;
    font-size: 12px;
  }
}
