/**
 * -- DEPRECATED - DO NOT EDIT --
 * Contains old accumulated style hacks and overrides.
 * We are migrating to a Bootstrap theme and will eventually delete this file.
 * See src/styles.scss for more info.
 */

/** TODO DELETE WHEN GENEIOUS BOOTSTRAP THEME HAS BETTER SUPPORT FOR ICON BUTTONS **/
.btn mat-icon.bx-btn-icon {
  font-size: 18px;
  height: 20px;
  width: 20px;
  vertical-align: middle;
}

mat-icon.icon-small {
  font-size: 18px;
  vertical-align: middle;
}

.mat-menu-panel {
  min-height: 5px !important;
}

.mat-menu-item {
  // Override material defaults.
  height: 25px !important;
  line-height: 25px !important;
  font-size: 12px !important;
}

mat-spinner {
  $spinner-height: 20px;
  // Override default material.
  width: $spinner-height !important;
  height: $spinner-height !important;
  display: inline-block;
  svg {
    // Override default material.
    width: 100% !important;
    height: 100% !important;
  }
}

$checkbox-size: 16px;

.mat-checkbox {
  font-size: $checkbox-size;
}

.mat-checkbox-inner-container {
  width: $checkbox-size !important;
  height: $checkbox-size !important;
}

.mat-radio-container {
  width: $checkbox-size !important;
  height: $checkbox-size !important;
}

.mat-radio-outer-circle {
  width: $checkbox-size !important;
  height: $checkbox-size !important;
}

.mat-radio-inner-circle {
  width: $checkbox-size !important;
  height: $checkbox-size !important;
}

.mat-radio-label {
  font-size: $checkbox-size;
}

// TODO Merge with accessLevelOption.
.mat-option.with-description {
  display: flex !important;
  flex-direction: column;
  line-height: normal !important;
  justify-content: space-around;
  height: 100% !important;
  width: 100%;
  padding: 10px 10px !important;
  font-size: 16px !important;
  box-sizing: border-box;

  .description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.54);
  }
}

// a.mat-tab-link is for result tabs.
// .mat-tab-label is for viewer tabs.
a.mat-tab-link,
.mat-tab-label {
  height: initial !important;
  padding: 3px 10px !important;
  line-height: 20px !important;
  min-width: 20px !important;
  display: inline-block;

  &:hover {
    // Show an effect so users don't think these tab links are disabled.
    // (Tab links are the styled the same as disabled buttons & our tab strips
    // are positioned near toolstrips which are often disabled).
    background-color: rgba(0, 0, 0, 0.12);
  }
}

a.mat-tab-link,
button a {
  // Don't style these links like the rest of the links in our app
  // because to the user they're not links.
  color: inherit !important;
  text-decoration: inherit !important;
}

.mat-ink-bar {
  // Transitions should be snappy.
  transition: 0.1s cubic-bezier(0.35, 0, 0.25, 1) !important;
}

$help-text-color: rgba(0, 0, 0, 0.32);

md-hint {
  color: $help-text-color;
}
