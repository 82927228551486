// Override color of select underline.
// This colour was changed in BETA.10 release of Material
// https://github.com/angular/material2/pull/5155
.mat-select-disabled .mat-select-value,
.mat-select-trigger,
.mat-select-arrow {
  color: rgba(0, 0, 0, 0.38) !important;
}

.mat-select-trigger .mat-select-underline {
  background-color: rgba(0, 0, 0, 0.12);
}
