.scatterplot-tooltip {
  position: absolute;
  text-align: center;
  padding: 2px 8px;
  font: 12px sans-serif;
  border: solid 2px lightsteelblue;
  background: white;
  border-radius: 8px;
  pointer-events: none;
  // Tooltip needs to appear over the top of the graph.
  z-index: 10;
}
