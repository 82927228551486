// Hide element if it doesn't have any content
.hidden-if-empty:empty {
  display: none;
}

// Make child visible when parent is hovered
// Normally used when a list item (the parent) has an icon button (the child)
.bx-hover-parent {
  .bx-visible-on-parent-hover {
    visibility: hidden;
  }

  .bx-display-on-parent-hover {
    display: none;
  }

  &:hover {
    .bx-visible-on-parent-hover {
      visibility: visible;
    }

    .bx-display-on-parent-hover {
      display: block;
    }
  }
}
