// angular-split overrides
// https://angular-split.github.io/documentation

.as-split-gutter {
  background-color: $gray-100 !important;
  :hover {
    background-color: $gray-300;
  }
}

.as-split-gutter-icon {
  background-image: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Material Icons';
  font-size: 24px;
  color: $dark;
}

// as-vertical has horizontal gutters: —
as-split.as-vertical > .as-split-gutter {
  max-height: $as-gutter-size;
  border-top: $btn-border-width solid $border-color !important;
  border-bottom: $btn-border-width solid $border-color !important;

  & > .as-split-gutter-icon:after {
    content: '\e5d3'; // more_horiz (codepoint to avoid FOUT)
  }
}

// as-horizontal has vertical gutters: |
as-split.as-horizontal > .as-split-gutter {
  max-width: $as-gutter-size;
  border-left: $btn-border-width solid $border-color !important;
  border-right: $btn-border-width solid $border-color !important;

  & > .as-split-gutter-icon:after {
    content: '\e5d4'; // more_vert (codepoint to avoid FOUT)
  }
}
